<template>
  <v-container>
    <h2 class="mb-5">Configuração de preço</h2>
    <table v-if="faixas.length">
      <tr>
        <th v-show="faixas.length > 1">
          Preço de custo a partir de
        </th>
        <th style="word-break: break-all;">Multiplicador</th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="(faixa, i) in faixas" :key="faixa.codigo">
        <td v-show="faixas.length > 1">
          <v-text-field
            solo
            dense
            v-money="money"
            v-model="faixa.precoDe"
            :disabled="i == 0 && faixas.length > 1"
            hide-details
          />
        </td>
        <td>
          <v-text-field
            solo
            dense
            v-mask="decimalMask"
            v-model="faixa.multiplicador"
            hide-details
          />
        </td>
        <td v-show="i > 0">
          <v-btn @click="adicionarAbaixo(i)" color="success" title="Adicionar nova faixa abaixo"
            >+</v-btn
          >
        </td>
        <td v-show="i > 0">
          <v-btn @click="remover(i)" color="error" title="Remover faixa">-</v-btn>
        </td>
      </tr>
      <tr v-show="faixas.length == 1">
        <td colspan="3" style="text-align: center;">
          <br />
          <v-btn @click="adicionarAbaixo(0)" color="success" outlined
            >Configurar por faixa de preço</v-btn
          >
        </td>
      </tr>
      <tr>
        <td colspan="3" style="text-align: center;">
          <br />
          <v-btn @click="salvar()" color="success">Salvar</v-btn>
        </td>
      </tr>
    </table>

    <div v-else-if="erro">
      Ocorreu um erro inesperado, tente novamente.
    </div>
  </v-container>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { VMoney } from "v-money";
import api from "../../api";

const decimalMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  decimalSymbol: ",",
  decimalLimit: 1,
  prefix: ""
});

export default {
  directives: { money: VMoney },

  data: () => ({
    faixas: [],
    ultimoCodigo: 0,
    erro: false,
    decimalMask,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ "
    }
  }),

  created() {
    api
      .get("?route=preco")
      .then(response => {
        this.faixas = response.data.map((faixa, i) => ({
          codigo: i + 1,
          precoDe: faixa.precoDe.toFixed(2),
          multiplicador: String(faixa.multiplicador).replace(".", ",")
        }));

        this.ultimoCodigo = this.faixas.length;
      })
      .catch(() => (this.erro = true));
  },

  methods: {
    adicionarAbaixo(index) {
      this.ultimoCodigo++;
      this.faixas.splice(index + 1, 0, {
        codigo: this.ultimoCodigo,
        precoDe: "",
        multiplicador: ""
      });
    },

    remover(i) {
      this.faixas.splice(i, 1);
    },

    salvar() {
      api
        .put("?route=preco", { faixas: this.faixas })
        .then(() => alert("Dados salvos com sucesso!"))
        .catch(err => alert(err.response.data.erro));
    }
  }
};
</script>
